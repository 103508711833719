import React from "react";
import GatsbyLink from "../GatsbyLink/GatsbyLink";
import classNames from "classnames";
import { ReactComponent as ArrowRight } from "../../assets/svgs/arrow-right.svg";
import PropTypes from "prop-types";

import "./BackButton.scss";

const BackButton = ({ to, children, className, variant = "light", ...others }) => {
  return (
    <GatsbyLink
      className={classNames("BackButton", "Body--small", variant, className)}
      to={to}
      {...others}
    >
      <ArrowRight className="icon" /> {children}
    </GatsbyLink>
  );
};

BackButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["light", "dark", "mixed"]),
};

export default BackButton;
