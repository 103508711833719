import React from "react";
import Insight from "../../templates/content-types/insight";
import insightsData from "../../page-data/insights/apis-are-not-enough-data";
import { useApisNotEnough } from "../../utils/custom-hooks/image-hooks";

const Insights = ({location}) => {
  return (
    <Insight
      {...insightsData}
      location={location}
      useImage={useApisNotEnough}
    />
  );
};

export default Insights;
